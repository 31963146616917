import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../I18n/I18nProvider";
import { useMsg } from "../../../components/Msg/Msg";
import { generalMessages } from "../../../components/messages";
import { messages } from "../messages";
import {
  useAuth,
  useMyMutation,
  useMyQuery,
} from "../../Authorization/AuthProvider";
import { FormProvider, useForm } from "react-hook-form";
import { TLDialog } from "../../../components/Dialog";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  DialogContent,
  Divider,
  Tooltip,
} from "@mui/material";
import { RHFTextField } from "../../../components/Forms";
import { formatName } from "../../Coaches/CoachCard";
import { addDays, intervalToDuration } from "date-fns";
import { IconWithProgress } from "./IconWithProgress";
import { fromZonedTime, zonedTimeToUtc } from "date-fns-tz";

const REMINDER_FIELDS = {
  subject: "subject",
  body: "body",
};

const ManualReminderDialog = ({
  open,
  onClose,
  feedbackId,
  senderName = "",
  deadlineDate = new Date(),
}) => {
  const { i18n } = useContext(I18nContext);
  const generalMsg = useMsg({ dict: generalMessages });
  const msg = useMsg({ dict: messages });
  const mutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/protected/jobs/feedback-notification`, // https://github.com/top-leader-cz/top-leader-be/pull/61/files
      // url: `/api/latest/feedback/${feedbackId}/manual-reminder`,
    },
  });

  const form = useForm({
    mode: "onSubmit",
    defaultValues: {
      [REMINDER_FIELDS.subject]: msg(
        "feedback.manual-reminder.form.subject.defaultValue",
        { name: senderName }
      ),
      [REMINDER_FIELDS.body]: msg(
        "feedback.manual-reminder.form.body.defaultValue",
        {
          name: senderName,
          deadlineDate: i18n.formatLocal(deadlineDate, "Pp"),
        }
      ),
    },
  });
  const onSubmit = (values) => {
    console.log("[ManualReminderDialog.onSubmit]", { values });
    mutation.mutate(values);
  };
  useEffect(() => {
    if (mutation.isSuccess) {
      onClose();
      form.reset();
    }
    if (!open) {
      form.clearErrors();
    }
  }, [open, mutation.isSuccess]);

  return (
    <TLDialog
      iconName={"Campaign"}
      title={msg("feedback.manual-reminder.title")}
      desc={undefined}
      open={open}
      onClose={onClose}
      // fullScreen
      fullWidth
      // maxWidth={"lg"}
      PaperProps={{
        style: {
          height: "95vh",
        },
      }}
      // sx={{ fisplay: "flex", flexDirection: "column", gap: 3 }}
      buttons={[
        {
          children: generalMsg("general.close"),
          variant: "outlined",
          onClick: onClose,
          // autoFocus: true,
          fullWidth: true,
        },
        {
          Component: LoadingButton,
          children: generalMsg("general.submit"),
          variant: "contained",
          onClick: form.handleSubmit(onSubmit),
          disabled: mutation.isPending,
          loading: mutation.isPending,
          // loadingPosition: "end",
          // autoFocus: true,
          fullWidth: true,
        },
      ]}
    >
      <FormProvider {...form}>
        <DialogContent
          dividers={false}
          sx={{
            overflowY: "visible",
          }}
        >
          <RHFTextField
            name="subject"
            parametrizedValidate={[
              ["required"],
              ["notBlank"],
              ["minLength", { gteLength: 1 }],
              ["maxLength", { lteLength: 250 }],
            ]}
            label={msg("feedback.manual-reminder.form.subject.label")}
            autoFocus
            size="small"
            fullWidth
          />
        </DialogContent>
        {/* <Divider flexItem /> */}
        <DialogContent
          dividers={false}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            height: "100%",
            // overflowY: "hidden",
            overflowY: "auto",
          }}
        >
          {/* <TextareaAutosize  /> */}
          <RHFTextField
            // Component={TextareaAutosize}
            name="body"
            parametrizedValidate={[
              ["required"],
              ["notBlank"],
              ["minLength", { gteLength: 1 }],
              ["maxLength", { lteLength: 3000 }],
            ]}
            label={msg("feedback.manual-reminder.form.body.label")}
            size="small"
            hiddenLabel
            multiline
            // rows={15}
            sx={
              {
                // height: "100%",
                // "& .MuiInputBase-root": {},
                // "& .MuiInputBase-input": {
                //   overflowY: "auto !important",
                //   overflow: "auto !important",
                // },
              }
            }
            fullWidth
            displayCharCounter
          />
        </DialogContent>
      </FormProvider>
    </TLDialog>
  );
};

const ManualReminderButton = ({ deadlineDate }) => {
  const msg = useMsg({ dict: messages });
  const [dialogOpen, setDialogOpen] = useState(false);
  const user = useAuth().userQuery.data;
  const senderName = formatName(user);

  return (
    <>
      <Button color="inherit" onClick={() => setDialogOpen(true)}>
        {msg("dict.feedback.results.status.canRemindManually.send")}
      </Button>
      <ManualReminderDialog
        feedbackId={undefined} // TODO: params
        senderName={senderName}
        deadlineDate={deadlineDate}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

const STATUS = {
  initial: "initial",
  reminded: "reminded",
  reminded_with_manual_possibility: "reminded_with_manual_possibility",
  canRemindManually: "canRemindManually",
  manuallyReminded: "manuallyReminded",
};

const msToHours = (ms) => ms / (1000 * 60 * 60);

const getPercentPassed = (firstDate, secondDate, nowDate) => {
  const durationTotal = intervalToDuration({
    start: firstDate,
    end: secondDate,
  });
  const durationPassed = intervalToDuration({
    start: firstDate,
    end: nowDate,
  });
  const totalMs = secondDate - firstDate;
  const remainingMs = secondDate - nowDate;
  const passedMs = nowDate - firstDate;
  const percentPassed = Math.round(passedMs / (totalMs / 100));

  console.log("[ReminderStatus.getPercentPassed]", percentPassed, {
    args: { firstDate, secondDate, nowDate },
    percentPassed,
    totalHours: msToHours(totalMs),
    remainingHours: msToHours(remainingMs),
    passedHours: msToHours(passedMs),
    durationTotal,
    durationPassed,
  });

  if (nowDate < firstDate) return 0;
  if (nowDate > secondDate) return 100;
  return percentPassed;
};

const formatDaysCount = ({ start, end }) => {
  return Math.round((end - start) / (1000 * 60 * 60 * 24));
};

const ReminderStatusInner = ({
  status = STATUS.initial,
  sendDate,
  reminderDate,
  manualDate,
  manualSentDate = manualDate,
  deadlineDate = new Date(),
}) => {
  const { i18n } = useContext(I18nContext);
  const msg = useMsg({ dict: messages });

  const color = "info";
  const manualInDays = formatDaysCount({
    start: new Date(),
    end: manualDate,
  });
  const reminderPercent = getPercentPassed(sendDate, reminderDate, new Date());
  const manualPercent = getPercentPassed(reminderDate, manualDate, new Date());
  console.log("[ReminderStatusInner]", {
    sendDate,
    reminderDate,
    manualDate,
    manualInDays,
    manualPercent,
    reminderPercent,
    reminderInDays: formatDaysCount({
      start: new Date(),
      end: reminderDate,
    }),
  });
  const DATE_FORMAT = "Pp";
  const propsByStatus = {
    initial: {
      text:
        msg.maybe("dict.feedback.results.status.initial.text", {
          reminderDate: i18n.formatLocal(reminderDate, DATE_FORMAT),
        }) || ".Automatic reminder to be sent on {reminderDate}",
      tooltip:
        msg.maybe("dict.feedback.results.status.initial.tooltip") ||
        "Automatic reminder is sent 5 days after the submission",
      // iconName: "Notifications",
      iconName: "Mail",
      valuePercent: reminderPercent,
    },
    reminded: {
      text:
        msg.maybe("dict.feedback.results.status.reminded.text", {
          reminderDate: i18n.formatLocal(reminderDate, DATE_FORMAT),
        }) || ".Automatic reminder sent on {reminderDate}",
      // tooltip:
      //   msg.maybe("dict.feedback.results.status.reminded.tooltip", {
      //     manualInDays,
      //   }) || ".Manual Reminder Available in {manualInDays} days",
      // iconName: "NotificationsActive",
      iconName: "NotificationImportant",
      // valuePercent: manualPercent,
    },
    reminded_with_manual_possibility: {
      text:
        msg.maybe("dict.feedback.results.status.reminded.text", {
          reminderDate: i18n.formatLocal(reminderDate, DATE_FORMAT),
        }) || ".Automatic reminder sent on {reminderDate}",
      tooltip:
        msg.maybe("dict.feedback.results.status.reminded.tooltip", {
          manualInDays,
        }) || ".Manual Reminder Available in {manualInDays} days",
      // iconName: "NotificationsActive",
      iconName: "NotificationImportant",
      valuePercent: manualPercent,
    },
    canRemindManually: {
      text:
        msg.maybe("dict.feedback.results.status.canRemindManually.text", {
          reminderDate: i18n.formatLocal(reminderDate, DATE_FORMAT),
        }) || ".Automatic reminder sent on {reminderDate}",
      tooltip: "",
      iconName: "Campaign",
      ActionComponent: ManualReminderButton,
      actionProps: { deadlineDate },
    },
    manuallyReminded: {
      text:
        msg.maybe("dict.feedback.results.status.manuallyReminded.text", {
          manualDate: i18n.formatLocal(manualSentDate, DATE_FORMAT),
        }) || ".Manual reminder sent on {manualDate}",
      tooltip: "",
      iconName: "Check",
    },
  };
  const {
    text,
    tooltip,
    iconName,
    valuePercent,
    ActionComponent,
    actionProps = {},
  } = propsByStatus[status];

  return (
    <Tooltip title={tooltip}>
      <Alert
        severity={color}
        variant="standard"
        color={color}
        action={
          ActionComponent && <ActionComponent {...actionProps} />
          // buttonProps && ( <Button color="inherit" size="small" {...buttonProps} /> )
        }
        icon={
          <IconWithProgress
            hideProgress={typeof valuePercent !== "number"}
            value={valuePercent}
            iconName={iconName}
            size={30}
            iconSize={"small"}
            thickness={3}
            color={color}
          />
        }
        // icon={<Icon name={iconName} fontSize="inherit" />}
      >
        {text}
      </Alert>
    </Tooltip>
  );
};

export const ReminderStatus = ({
  feedbackId,
  feedbackMaybe,
  withDividerTop,
}) => {
  const { i18n } = useContext(I18nContext);
  const { userQuery } = useAuth();
  const msg = useMsg({ dict: messages });

  /*
    {// feedbackFormEmailTime, manualReminderAllowedAfter
    "notificationTime": "2023-10-12T02:00:00", - automaticReminderTime
    "feedbackFormId": 1,
    "processedTime": "2023-10-12T02:00:00", - automaticReminderSentTime
    "status": "AUTOMATIC_REMINDER_SENT",
  } // manualReminderAllowed (bool)
  */
  const query = useMyQuery({
    queryKey: ["feedback", "notification", feedbackId],
    fetchDef: {
      url: `/api/latest/feedback-notification/${feedbackId}`,
    },
  });
  console.log("[ReminderStatus.rndr]", {
    feedbackId,
    feedbackMaybe,
    queryData: query.data,
  });

  if (!feedbackMaybe || !query.data) return null;

  const { notificationTime, processedTime, status: notifStatus } = query.data;
  const userTz = userQuery.data.timeZone;
  const _reminderDate = processedTime
    ? zonedTimeToUtc(processedTime, userTz)
    : zonedTimeToUtc(notificationTime, userTz);
  // const _reminderDateStr = processedTime
  //   ? i18n.parseUTCLocal(processedTime)
  //   : i18n.parseUTCLocal(notificationTime);

  const reminderDate = _reminderDate;
  // const reminderDate = zonedTimeToUtc(_reminderDateStr, userQuery.data.timeZone);
  const sendDate = addDays(reminderDate, -5);
  const manualDate = addDays(reminderDate, 3);

  const deadlineDate = addDays(sendDate, 21);
  const status = processedTime ? STATUS.reminded : STATUS.initial;

  return (
    <>
      {withDividerTop && <Divider sx={{ my: 3 }} />}
      <ReminderStatusInner
        status={status}
        sendDate={sendDate}
        reminderDate={reminderDate}
        manualDate={manualDate}
        manualSentDate={manualDate}
        deadlineDate={deadlineDate}
      />
    </>
  );
};

export const ReminderStatusExamples = () => {
  const now = new Date();
  const sendDate = addDays(now, -2);
  const reminderDate = addDays(sendDate, 5);
  const manualDate = addDays(reminderDate, 3);
  const deadlineDate = addDays(now, 21);

  return (
    <>
      <Divider sx={{ my: 3 }} />
      All statuses examples:
      <Box sx={{ my: 3 }} />
      <ReminderStatusInner
        status={STATUS.initial}
        sendDate={sendDate}
        reminderDate={reminderDate}
        manualDate={manualDate}
      />
      <Box sx={{ pb: 3 }} />
      <ReminderStatusInner
        status={STATUS.reminded}
        sendDate={sendDate}
        reminderDate={reminderDate}
        manualDate={manualDate}
      />
      <Box sx={{ pb: 3 }} />
      <ReminderStatusInner
        status={STATUS.reminded_with_manual_possibility}
        sendDate={sendDate}
        reminderDate={reminderDate}
        manualDate={manualDate}
      />
      <Box sx={{ pb: 3 }} />
      <ReminderStatusInner
        status={STATUS.canRemindManually}
        sendDate={sendDate}
        reminderDate={reminderDate}
        manualDate={manualDate}
        deadlineDate={deadlineDate}
      />
      <Box sx={{ pb: 3 }} />
      <ReminderStatusInner
        status={STATUS.manuallyReminded}
        sendDate={sendDate}
        reminderDate={reminderDate}
        manualDate={manualDate}
      />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
    </>
  );
};
