import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { MsgProvider } from "../../components/Msg";
import { Msg, useMsg } from "../../components/Msg/Msg";
import { ScrollableRightMenu } from "../../components/ScrollableRightMenu";
import { routes } from "../../routes";
import { I18nContext } from "../I18n/I18nProvider";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { SessionStepCard } from "./SessionStepCard";
import { VerticalStepper } from "./VerticalStepper";
import { useUserSessionMutation, useUserSessionQuery } from "./api";
import { sessionsMessages } from "./messages";
import { DEFAULT_VALUE_ROW } from "./steps/ActionStepsStep";
import { Finished } from "./steps/Finished";
import { useNewSessionSteps, useSteps } from "./steps/useSessionSteps";

export const StepperRightMenu = ({
  heading,
  buttonProps,

  activeStepIndex = 0,
  onStepClick,
  steps,
}) => {
  return (
    <ScrollableRightMenu heading={heading} buttonProps={buttonProps}>
      <VerticalStepper
        activeStepIndex={activeStepIndex}
        // onStepClick={onStepClick}
        steps={steps}
      />
    </ScrollableRightMenu>
  );
};

function NewSessionPageInner() {
  const { i18n } = useContext(I18nContext);
  const msg = useMsg();
  const navigate = useNavigate();
  const query = useUserSessionQuery();
  const mutation = useUserSessionMutation({});

  const { steps: STEPS } = useNewSessionSteps();
  const {
    activeStep: { StepComponent = SessionStepCard, ...activeStep } = {},
    activeStepIndex,
    handleNext,
    handleBack,
    data,
    setData,
  } = useSteps({
    steps: STEPS,
    initialIndex: 0,
    initialData: {
      actionSteps: DEFAULT_VALUE_ROW,
    },
  });

  return (
    <Layout
      rightMenuContent={
        <StepperRightMenu
          heading={
            <>
              <Msg id="sessions.new.aside.title" />
              &nbsp; {i18n.formatLocal(new Date(), "P")}
            </>
          }
          activeStepIndex={activeStepIndex}
          steps={STEPS}
        />
      }
    >
      <Header
        // text={generalMsg("general.back")}
        // back={{ href: routes.dashboard }}
        actionButton={{
          variant: "text",
          color: "error",
          children: msg("sessions.new.aside.end-button"),
          onClick: () => navigate(routes.dashboard),
          startIcon: <Icon name="StopCircle" />,
        }}
      />
      {query.isFetching || mutation.isPending ? (
        <QueryRenderer isPending loaderEl={<Loaders.CircularBlock spaced />} />
      ) : mutation.isSuccess ? (
        <Finished />
      ) : (
        <StepComponent
          step={activeStep}
          stepper={{ currentIndex: activeStepIndex, totalCount: STEPS.length }}
          data={data}
          setData={setData}
          handleNext={handleNext}
          handleBack={handleBack}
          onFinish={mutation.mutate}
          disabled={mutation.isPending}
        />
      )}
    </Layout>
  );
}

export function NewSessionPage() {
  return (
    <MsgProvider messages={sessionsMessages}>
      <NewSessionPageInner />
    </MsgProvider>
  );
}
