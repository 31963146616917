import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { filter, flip, includes, pipe, prop } from "ramda";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { getCoachLanguagesOptions } from "../../components/Forms";
import {
  AutocompleteSelect,
  SliderField,
  SwitchField,
} from "../../components/Forms/Fields";
import { RHForm } from "../../components/Forms/Form";
import { Icon } from "../../components/Icon";
import { Msg, useMsg } from "../../components/Msg/Msg";
import { I18nContext } from "../I18n/I18nProvider";
import { ControlsContainer } from "../Sessions/steps/Controls";
import { useFieldsDict } from "../Settings/useFieldsDict";
import { useAuth } from "../Authorization";

export const INITIAL_FILTER = () => ({
  // languages: [userLang.substring(0, 2)],
  languages: [],
  fields: [],
  experience: [1, 70],
  prices: [],
  search: "",
  displayFavorites: false,
});

export const ratesOptions = [
  { value: "$", label: "$" },
  { value: "$$", label: "$$" },
  { value: "$$$", label: "$$$" },
];

const limitRatesOptions = (allowedCoachRates) => {
  if (!allowedCoachRates?.length) return ratesOptions;
  return ratesOptions.filter(({ value }) => allowedCoachRates.includes(value));
};

const RATES = [
  { label: "$", credits: 110 },
  { label: "$$", credits: 165 },
  { label: "$$$", credits: 275 },
];

const Rates = ({ msg, rates = RATES }) => {
  return (
    <>
      <Box display="flex">
        <Box>
          {rates.map(({ label }) => (
            <Box key={label}>{label}</Box>
          ))}
        </Box>
        <Box>
          {rates.map(({ credits }) => (
            <Box key={credits}>
              {" = "}
              {msg("coaches.filter.rate.tooltip.credits-per-session", {
                credits,
              })}
            </Box>
          ))}
        </Box>
      </Box>
      <Box textAlign="center">{msg("coaches.filter.rate.tooltip.info")}</Box>
    </>
  );
};

export const filterOptions = (supportedValues) =>
  filter(pipe(prop("value"), flip(includes)(supportedValues)));

export const CoachesFilter = ({
  filter,
  setFilter,
  supportedLanguages = [],
  supportedFields = [],
  favoritesDisplayed = false,
  favoritesDisabled = false,
  sx = {},
}) => {
  const { user } = useAuth();
  const msg = useMsg();
  const { fieldsOptions: unfilteredFieldsOptions } = useFieldsDict();
  const methods = useForm({
    defaultValues: filter,
  });

  const languages = methods.watch("languages");
  const fields = methods.watch("fields");
  const experience = methods.watch("experience");
  const prices = methods.watch("prices");
  const search = methods.watch("search");
  const displayFavorites = methods.watch("displayFavorites");

  // excluding search - user needs to click the button
  const reactiveValues = useMemo(
    () => ({ languages, fields, experience, prices, displayFavorites }),
    [languages, fields, experience, prices, displayFavorites]
  );
  const setFilterRef = useRef(setFilter);
  setFilterRef.current = setFilter;
  useEffect(() => {
    setFilterRef.current((filter) => ({ ...filter, ...reactiveValues }));
  }, [reactiveValues]);
  const onSearch = useCallback(
    (searchStr = search) => {
      // const root = e.target.closest(".MuiInputBase-root");
      // const input = root?.querySelector("input");
      // const value = input?.value || "";
      console.log("onSearch", { searchStr, search });
      setFilter((filter) => ({ ...filter, search: searchStr }));
    },
    [search, setFilter]
  );
  const onClearFilters = () => {
    console.log("RESETTING");
    const initialValues = INITIAL_FILTER();
    methods.setValue("languages", initialValues.languages);
    methods.setValue("fields", initialValues.fields);
    methods.setValue("experience", initialValues.experience);
    methods.setValue("prices", initialValues.prices);
    methods.setValue("search", initialValues.search);
    methods.setValue("displayFavorites", initialValues.displayFavorites);

    onSearch("");
  };
  useEffect(() => {
    if (!favoritesDisplayed || favoritesDisabled)
      methods.setValue("displayFavorites", false);
  }, [favoritesDisplayed, favoritesDisabled, methods]);
  const languageOptions = useMemo(
    () => pipe(getCoachLanguagesOptions, filterOptions(supportedLanguages))(),
    [supportedLanguages]
  );
  const fieldsOptions = useMemo(
    () => filterOptions(supportedFields)(unfilteredFieldsOptions),
    [supportedFields, unfilteredFieldsOptions]
  );
  const limitedRatesOptions = useMemo(
    () => limitRatesOptions(user.data?.allowedCoachRates),
    [user.data?.allowedCoachRates]
  );

  return (
    <RHForm
      form={methods}
      onSubmit={(values) =>
        console.log("onSubmit", { values }) || onSearch(values.search)
      }
    >
      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ "&:last-child": { pb: 2 } }}>
          {/* <Box display="flex" flexDirection="row" gap={3}> */}
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 4, md: 2 }}
          >
            <AutocompleteSelect
              name="languages"
              label={msg("coaches.filter.language.label")}
              options={languageOptions}
              multiple
            />
            <AutocompleteSelect
              name="fields"
              label={msg("coaches.filter.field.label")}
              options={fieldsOptions}
              multiple
              disableCloseOnSelect={false}
            />
            <SliderField
              name="experience"
              label={msg("coaches.filter.experience.label")}
              range={[1, 70]}
            />
            {limitedRatesOptions?.length > 1 && (
              // <Tooltip title={<Rates msg={msg} />} placement="top">
              <AutocompleteSelect
                name="prices"
                label={msg("coaches.filter.rate.label")}
                options={limitedRatesOptions}
                multiple
              />
            )}

            {/* <OutlinedField label="Test" /> */}
          </Stack>
          {/* </Box> */}
          <Divider sx={{ mt: 3, mb: 2 }} />
          <ControlsContainer>
            <Button
              onClick={onClearFilters}
              variant="text"
              startIcon={<Icon name="Close" />}
              sx={{ p: 1 }}
            >
              <Msg id="coaches.filter.clear-button" />
            </Button>
          </ControlsContainer>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Controller
          name={"search"}
          // rules={{ minLength: 5 }}
          render={({ field, fieldState, formState }) => (
            <TextField
              // disabled
              autoFocus
              sx={{
                width: 360,
                "> .MuiInputBase-root": { bgcolor: "white" },
              }}
              label=""
              placeholder={msg("coaches.filter.search.placeholder")}
              size="small"
              {...field}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      // onClick={() => onSearch()}
                      sx={{
                        mr: -2,
                        px: 1,
                        minWidth: "auto",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      variant="contained"
                      type="submit"
                    >
                      <Icon name="Search" />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Tooltip
          title={
            favoritesDisabled
              ? msg("coaches.filter.favorites.tooltip.empty", {
                  icon: (
                    <Icon
                      name={"BookmarkAddOutlined"}
                      sx={{ fontSize: "inherit" }}
                    />
                  ),
                })
              : null
          }
        >
          <FormControlLabel
            value="start"
            control={<SwitchField name="displayFavorites" />}
            label={msg("coaches.filter.favorites.label")}
            labelPlacement="start"
            disabled={favoritesDisabled}
            sx={{
              visibility: favoritesDisplayed ? "visible" : "hidden",
            }}
          />
        </Tooltip>
      </Box>
    </RHForm>
  );
};
