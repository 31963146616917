import {
  descend,
  evolve,
  map,
  mergeRight,
  omit,
  pick,
  pipe,
  prop,
  slice,
  sort,
  trim,
} from "ramda";
import { useContext, useRef } from "react";
import {
  useAuth,
  useMyMutation,
  useMyQuery,
} from "../Authorization/AuthProvider";
import { EXTERNAL_FEEDBACK_FIELDS, FEEDBACK_FIELDS } from "./constants";
import { I18nContext } from "../I18n/I18nProvider";

export const useFeedbackOptionsQuery = (rest = {}) => {
  const query = useMyQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    queryKey: ["feedback", "options"],
    fetchDef: { url: `/api/public/latest/feedback/options` },
    ...rest,
  });
  return query;
};

export const useExternalFeedbackQuery = ({
  params: { formId, username, token },
  ...rest
} = {}) => {
  const externalFeedbackQuery = useMyQuery({
    queryKey: ["feedback", "external"],
    fetchDef: {
      url: `/api/public/latest/feedback/${formId}/${username}/${token}`,
    },
    retry: 1,
    refetchOnWindowFocus: false,
    ...rest,
  });

  return externalFeedbackQuery;
};

export const useExternalFeedbackMutation = ({
  params: { formId, username, token },
  ...rest
} = {}) => {
  const mutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/public/latest/feedback/${formId}/${username}/${token}`,
      from: evolve({
        [EXTERNAL_FEEDBACK_FIELDS.answers]: map(
          pipe(pick(["question", "answer"]), evolve({ answer: trim }))
        ),
      }),
    },
    ...rest,
  });
  return mutation;
};

export const useRequestAccessMutation = ({
  params: { formId, username, token },
  ...rest
} = {}) => {
  const mutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/public/latest/feedback/request-access/${formId}/${username}/${token}`,
    },
    ...rest,
  });
  return mutation;
};

const feedbackFrom = ({ language }) =>
  pipe(
    mergeRight({ locale: slice(0, 2, language) }),
    evolve({ [FEEDBACK_FIELDS.title]: trim })
  );

export const useCreateFeedbackFormMutation = (rest = {}) => {
  const { language } = useContext(I18nContext);
  const mutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: "/api/latest/feedback",
      from: pipe(feedbackFrom({ language }), omit(["id"])),
    },
    invalidate: [{ exact: false, queryKey: ["feedback"] }],
    ...rest,
  });
  return mutation;
};

export const useUpdateFeedbackFormMutation = ({
  params: { id },
  ...rest
} = {}) => {
  const { language } = useContext(I18nContext);
  const mutation = useMyMutation({
    // debug: true,
    fetchDef: {
      method: "PUT",
      url: `/api/latest/feedback/${id}`,
      from: feedbackFrom({ language }),
    },
    invalidate: [{ queryKey: ["feedback"] }],
    removeQueries: [{ queryKey: ["feedback", `${id}`] }],
    // refetchQueries: [{ queryKey: ["feedback", `${id}`] }], // not saved to cache?!?
    ...rest,
  });
  return mutation;
};

export const useFeedbackQuery = ({ params: { id }, ...rest } = {}) => {
  return useMyQuery({
    queryKey: ["feedback", `${id}`],
    fetchDef: { url: `/api/latest/feedback/${id}` },
    ...rest,
  });
};

export const useDeleteFeedbackFormMutation = (rest = {}) => {
  return useMyMutation({
    fetchDef: {
      method: "DELETE",
      getUrl: ({ id }) => `/api/latest/feedback/${id}`,
    },
    invalidate: [{ exact: false, queryKey: ["feedback", "list"] }],
    ...rest,
  });
};

export const useFeedbackFormsQuery = ({ ...rest } = {}) => {
  const username = useAuth().user.data.username;
  const query = useMyQuery({
    queryKey: ["feedback", "list", username],
    fetchDef: {
      url: `/api/latest/feedback/user/${username}`,
      to: sort(descend(prop("createdAt"))),
    },
    ...rest,
  });
  return query;
};

export const useFeedbackResultsQuery = ({ params: { id }, ...rest } = {}) => {
  const query = useMyQuery({
    queryKey: ["feedback", "results", id],
    fetchDef: { url: `/api/latest/feedback/${id}` }, // TODO: currently same as useFeedbackQuery, but used for DnD
    ...rest,
  });
  return query;
};
