import { alpha, createTheme } from "@mui/material/styles";
import defaultTheme from "@mui/material/styles/defaultTheme";
import { LinkBehavior } from "./components/LinkBehavior";

export const primary25 = "#F9F8FF";
export const primary500 = "#4720B7";
export const gray50 = "#F9FAFB"; // previously in bg as #EAECF0
export const gray200 = "#EAECF0";
export const gray500 = "#667085";
export const gray900 = "#101828";

const elevationBaselineColor = "0,0,0";

const elevationUmbraOpacity = "0.05";
const elevationPenumbraOpacity = "0.05";
const elevationAmbientOpacity = "0.15";
// original values
// const elevationUmbraOpacity = "0.2";
// const elevationPenumbraOpacity = "0.14";
// const elevationAmbientOpacity = "0.12";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: primary500,
    },
    secondary: {
      main: "#907ACF",
    },
    warning: {
      contrastText: "#EAAA08", // yellow/500
      main: "#FEF7C3", // yellow/100
    },
    // info: {
    //   main: primary500,
    // },
    error: {
      main: "#F04438",
      // light: "#757ce8",
      // main: "#3f50b5",
      // dark: "#002884",
      // contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    // fontWeightLight: 200,
    // fontWeightBold: 600,
    h1: {
      fontSize: 24,
      fontWeight: 600,
      color: gray900,
      lineHeight: 1.21,
      letterSpacing: "0em",
    },
    h2: {
      fontSize: 18,
      fontWeight: 600,
      color: gray900,
      lineHeight: 1.21,
      letterSpacing: "0em",
    },
    h3: {
      fontSize: 14,
      fontWeight: 600,
      color: gray900,
      lineHeight: 1.21,
      letterSpacing: "0em",
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      color: gray500,
      lineHeight: 1.21,
    },
    // body2: {
    //   fontSize: 18,
    //   fontWeight: 400,
    //   color: gray900,
    //   lineHeight: 1.21,
    // },
    button: {
      fontSize: 14,
      fontWeight: 500,
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 10,
  },
  // https://github.com/material-components/material-components-web/blob/be8747f94574669cb5e7add1a7c54fa41a89cec7/packages/mdc-elevation/_variables.scss
  // default:
  shadows: [
    "none",
    `0px 2px 1px -1px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 1px 1px 0px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 1px 3px 0px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 3px 1px -2px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 2px 2px 0px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 1px 5px 0px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 3px 3px -2px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 3px 4px 0px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 1px 8px 0px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 2px 4px -1px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 4px 5px 0px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 1px 10px 0px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 3px 5px -1px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 5px 8px 0px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 1px 14px 0px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 3px 5px -1px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 6px 10px 0px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 1px 18px 0px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 4px 5px -2px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 7px 10px 1px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 2px 16px 1px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 5px 5px -3px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 8px 10px 1px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 3px 14px 2px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 5px 6px -3px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 9px 12px 1px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 3px 16px 2px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 6px 6px -3px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 10px 14px 1px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 4px 18px 3px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 6px 7px -4px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 11px 15px 1px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 4px 20px 3px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 7px 8px -4px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 12px 17px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 5px 22px 4px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 7px 8px -4px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 13px 19px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 5px 24px 4px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 7px 9px -4px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 14px 21px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 5px 26px 4px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 8px 9px -5px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 15px 22px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 6px 28px 5px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 8px 10px -5px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 16px 24px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 6px 30px 5px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 8px 11px -5px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 17px 26px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 6px 32px 5px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 9px 11px -5px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 18px 28px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 7px 34px 6px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 9px 12px -6px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 19px 29px 2px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 7px 36px 6px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 10px 13px -6px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 20px 31px 3px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 8px 38px 7px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 10px 13px -6px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 21px 33px 3px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 8px 40px 7px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 10px 14px -6px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 22px 35px 3px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 8px 42px 7px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 11px 14px -7px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 23px 36px 3px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 9px 44px 8px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
    `0px 11px 15px -7px rgba(${elevationBaselineColor},${elevationUmbraOpacity}),0px 24px 38px 3px rgba(${elevationBaselineColor},${elevationPenumbraOpacity}),0px 9px 46px 8px rgba(${elevationBaselineColor},${elevationAmbientOpacity})`,
  ],
  components: {
    // MuiTypography: {
    //   defaultProps: {
    //     variantMapping: {
    //       h2: "h1",
    //       h3: "h2",
    //       h4: "h3",
    //       subtitle1: "h2",
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        input: ({ ownerState }) => ({
          ...(ownerState?.size === "small" && {
            "&.MuiInputBase-inputSizeSmall": { padding: "9.5px 14px" },
          }),
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.Mui-selected.MuiListItemButton-root": {
            backgroundColor: primary25,
            ".MuiTypography-root,.MuiListItemIcon-root": {
              color: primary500,
            },
          },
        },
      },
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: "inherit",
        },
      },
      variants: [
        {
          props: { variant: "selected" },
          style: {
            color: primary500,
            backgroundColor: primary25,
            border: `1px solid ${primary500}`,
            padding: "16px",
            borderRadius: "10px",
          },
        },
        {
          props: { variant: "unselected" },
          style: {
            color: gray900,
            backgroundColor: "#FFFFFF",
            border: "1px solid transparent",
            padding: "16px",
            borderRadius: "10px",
          },
        },
      ],
    },
  },
});

// console.log({ theme, defaultTheme });

export default theme;
