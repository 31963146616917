import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  Tooltip,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { intervalToDuration } from "date-fns";
import { isBefore } from "date-fns/fp";
import { evolve, move, prop } from "ramda";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { RHFTextField } from "../../components/Forms";
import { RHForm } from "../../components/Forms/Form";
import { Header } from "../../components/Header";
import { Icon } from "../../components/Icon";
import { Layout, useRightMenu } from "../../components/Layout";
import { ListDnD } from "../../components/ListDnD/ListDnD";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { H1, P } from "../../components/Typography";
import { routes } from "../../routes";
import { gray900, primary25 } from "../../theme";
import { HeadingWithIcon } from "../Dashboard/HeadingWithIcon";
import { I18nContext } from "../I18n/I18nProvider";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { controlsMessages } from "../Sessions/steps/Controls";
import { useDevMode } from "../Settings/Settings.page";
import { useFeedbackResultsQuery, useUpdateFeedbackFormMutation } from "./api";
import { FeedbackRightMenu } from "./FeedbackRightMenu";
import { getCollectedMaybe } from "./GetFeedback.page";
import { messages } from "./messages";
import {
  ReminderStatus,
  ReminderStatusExamples,
} from "./ReminderStatus/ReminderStatus";
import { FieldResultsCard } from "./Results";
import { ErrorBoundary } from "../../components/ErrorBoundary";

export const ConditionalWrapper = ({
  condition,
  WrapperComponent,
  wrapperProps = {},
  renderWrapped = ({ children }) => (
    <WrapperComponent {...wrapperProps}>{children}</WrapperComponent>
  ),
  children,
}) => {
  return condition ? renderWrapped({ children }) : children;
};

const AddRecipient = ({ feedback, onSuccess }) => {
  const msg = useMsg({ dict: messages });
  const form = useForm({
    defaultValues: { recipient: "" },
  });
  const mutation = useUpdateFeedbackFormMutation({
    params: { id: feedback?.id },
    enabled: !!feedback?.id,
    onSuccess: () => {
      form.reset();
      onSuccess?.();
    },
  });
  const handleSubmit = ({ recipient }) => {
    const updated = evolve({
      recipients: (prev) => [...prev, { username: recipient }],
    })(feedback);
    mutation.mutate(updated);
  };
  const loading = mutation.isPending;

  return (
    <RHForm form={form} onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 2,
          mt: 1,
        }}
      >
        <RHFTextField
          name={"recipient"}
          placeholder={msg("feedback.results.email.placeholder")}
          rules={{ required: "Required" }}
          size="small"
          sx={{ flexGrow: 2 }}
          autoFocus
        />
        <LoadingButton variant="contained" type="submit" loading={loading}>
          {msg("feedback.results.add")}
        </LoadingButton>
      </Box>
    </RHForm>
  );
};

function useInterval(callback, delay) {
  const savedCallback = useRef();
  savedCallback.current = callback;

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const SharedWith = ({ feedback }) => {
  const msg = useMsg({ dict: messages });
  const [addVisible, setAddVisible] = useState(false);
  const handleAdd = () => setAddVisible(true);

  const [, rerender] = useReducer((x) => x + 1, 0);
  useInterval(rerender, 60000);
  const { i18n } = useContext(I18nContext);
  const isStillValid = isBefore(
    i18n.parseUTCLocal(feedback.validTo),
    Date.now()
  );
  const timeToExpire = i18n.dffp.formatDurationWithMergeOptions(
    {},
    intervalToDuration({
      start: Date.now(),
      end: i18n.parseUTCLocal(feedback.validTo),
    })
  );
  // const timeToExpire = i18n.dffp.formatDistanceWithMergeOptions(
  //   { addSuffix: false, includeSeconds: true },
  //   i18n.parseUTCLocal(feedback.validTo),
  //   Date.now()
  // );

  console.log("[SharedWith.rndr]", { feedback, isStillValid, timeToExpire });

  // if (!feedback?.recipients) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <P bigger sx={{ color: "black" }}>
          {msg("feedback.results.shared-with")}
        </P>
        <ConditionalWrapper
          condition={isStillValid}
          WrapperComponent={Tooltip}
          wrapperProps={{
            title: timeToExpire,
          }}
        >
          <Button
            variant="text"
            startIcon={<Icon name="Add" />}
            onClick={handleAdd}
            sx={{
              ml: 1,
              visibility: addVisible ? "hidden" : "visible",
            }}
          >
            {msg("feedback.results.add-email")}
          </Button>
        </ConditionalWrapper>
      </Box>

      {addVisible && (
        <AddRecipient
          feedback={feedback}
          onSuccess={() => setAddVisible(false)}
        />
      )}

      {feedback?.recipients?.map(({ username, submitted }, i) => (
        <P key={username} bigger sx={{ mt: 2 }}>
          {username}
        </P>
      ))}
    </Box>
  );
};

// “Sent Initial Invite”, “Automatic Reminder Sent (5 days ago)”, “Manual Reminder Available in 3 Days”.

const SUMMARY_MOCK = {
  strongAreas:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  areasOfImprovement: "areasOfImprovement",
};

const InfoBox = ({ title, text, sx = {} }) => {
  return (
    <Box
      sx={{
        bgcolor: primary25,
        mb: 1,
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p: 2,
        ...sx,
      }}
    >
      <HeadingWithIcon emphasized withoutIcon title={title} />
      <P bigger sx={{ color: gray900 }}>
        {text}
      </P>
    </Box>
  );
};

const FeedbackResultsSummaryCard = ({ summary, sx }) => {
  const msg = useMsg({ dict: messages });
  const { strongAreas, areasOfImprovement } = summary || {};

  if (!strongAreas && !areasOfImprovement) return null;

  return (
    <Card sx={sx}>
      <Accordion
        defaultExpanded
        sx={{
          // bgcolor: gray50,
          borderRadius: "8px",
          "&:before": {
            display: "none", // remove border
          },
        }}
      >
        <AccordionSummary
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <HeadingWithIcon emphasized title={msg("feedback.results.summary")} />
        </AccordionSummary>
        {/* <CardActionArea sx={{}} href={href}> */}
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "stretch",
            gap: 3,
            pt: 0,
          }}
        >
          {!!strongAreas && (
            <InfoBox
              sx={{ flex: 1 }}
              title={msg("feedback.results.summary.strong-areas")}
              text={strongAreas}
            />
          )}
          {!!areasOfImprovement && (
            <InfoBox
              sx={{ flex: 1 }}
              title={msg("feedback.results.summary.areas-of-improvement")}
              text={areasOfImprovement}
            />
          )}
        </AccordionDetails>
        {/* </CardActionArea> */}
      </Accordion>
    </Card>
  );
};

function FeedbackResultsPageInner() {
  const { id } = useParams();
  const msg = useMsg();
  const queryClient = useQueryClient();
  const query = useFeedbackResultsQuery({ params: { id } });
  const moveMutation = useUpdateFeedbackFormMutation({
    params: { id },
    // make sure to return the Promise from the query invalidation
    // so that the mutation stays in `pending` state until the refetch is finished
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: ["feedback", "results", id],
      });
    },
  });
  const controlsMsg = useMsg({ dict: controlsMessages });
  const [isDevMode] = useDevMode();

  // console.log("[FeedbackResultsPageInner]", { data: query.data });

  useRightMenu(
    useMemo(() => {
      const collected = getCollectedMaybe(query.data);
      console.log("[FeedbackResultsPageInner.memo]", {
        data: query.data,
        collected,
      });

      return (
        <FeedbackRightMenu
          isPending={!query.data}
          collected={collected}
          stats={[
            {
              label: msg("feedback.respondents"),
              value: query.data?.recipients?.length || 0,
            },
            {
              label: msg("feedback.submitted"),
              value:
                query.data?.recipients?.filter(prop("submitted"))?.length || 0,
            },
          ]}
          // buttonProps={{ children: "Share form", onClick: onShareForm, }}
        >
          <ErrorBoundary fallbackRender={() => null}>
            <ReminderStatus
              feedbackId={id}
              feedbackMaybe={query.data}
              withDividerTop
            />
          </ErrorBoundary>
          <Divider sx={{ my: 3 }} />
          <SharedWith feedback={query.data} />
          {isDevMode && <ReminderStatusExamples />}
        </FeedbackRightMenu>
      );
    }, [id, isDevMode, msg, query.data])
  );
  const handleMove = useCallback(
    ({ sourceIndex, destinationIndex, feedback }) => {
      const newFeedback = evolve({
        questions: move(sourceIndex, destinationIndex),
      })(feedback);
      console.log("[FeedbackResultsPageInner.handleMove]", {
        sourceIndex,
        destinationIndex,
        feedback,
        newFeedback,
      });
      moveMutation.mutate(newFeedback);
    },
    [moveMutation]
  );

  return (
    <Layout initialPrintLeftMenuHidden>
      <Header
        // back={{ href: routes.dashboard }}
        // text={msg("feedback.create.heading")} // TODO: change textation and use this
        text={controlsMsg("controls.back")}
        back={{ href: routes.getFeedback }}
      />
      <H1 mb={4}>{msg("feedback.heading")}</H1>
      <QueryRenderer
        alwaysSuccess={
          moveMutation.isPending ? { data: moveMutation.variables } : undefined
        }
        query={query}
        loaderEl={<Loaders.CircularBlock spaced />}
        success={({ data }) => {
          console.log("[FeedbackResultsPageInner.QueryRenderer.rndr]", {
            data,
          });
          return (
            <Box
              sx={{
                opacity: moveMutation.isPending ? 0.5 : 1,
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <FeedbackResultsSummaryCard
                summary={data?.summary}
                // summary={SUMMARY_MOCK}
              />
              <ListDnD.Wrapper
                onDragEnd={(result) => {
                  const { source, destination } = result;
                  if (destination)
                    handleMove({
                      sourceIndex: source.index,
                      destinationIndex: destination.index,
                      feedback: data,
                    });
                }}
                sx={{ display: "flex", flexDirection: "column", gap: 3 }}
              >
                {data?.questions?.map((question, index) => (
                  <ListDnD.Item
                    key={question.id ?? JSON.stringify(question)}
                    draggableId={question.id ?? JSON.stringify(question)}
                    index={index}
                    isDragDisabled={
                      !isDevMode ||
                      data?.questions?.length < 2 ||
                      moveMutation.isPending
                    }
                  >
                    <FieldResultsCard
                      key={question.id ?? JSON.stringify(question)}
                      index={index}
                      question={question}
                      feedback={data}
                      sx={{ bgcolor: isDevMode ? primary25 : undefined }} // TODO: remove
                    />
                  </ListDnD.Item>
                ))}
              </ListDnD.Wrapper>
            </Box>
          );
        }}
      />
    </Layout>
  );
}

export function FeedbackResultsPage() {
  return (
    <MsgProvider messages={messages}>
      <FeedbackResultsPageInner />
    </MsgProvider>
  );
}
