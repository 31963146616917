import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { filter } from "ramda";
import { gray900 } from "../../theme";
import { Icon } from "../Icon";
import { H2, P } from "../Typography";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  "& .MuiDialogContent-root": {
    padding: `${theme.spacing(3)} 0`,
  },
  "& .MuiDialogContentText-root": {
    color: gray900,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
    paddingTop: theme.spacing(3),
  },
}));

const OldHeader = ({ iconName, onClose }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Avatar sx={{ bgcolor: "#F9FAFB", width: 48, height: 48 }}>
        <Avatar sx={{ bgcolor: "#EAECF0", width: 36, height: 36 }}>
          <Icon name={iconName} sx={{ color: "#667085" }} />
        </Avatar>
      </Avatar>
      <IconButton onClick={onClose}>
        <Icon name="Close" sx={{ color: "#667085" }} />
      </IconButton>
    </Box>
  );
};

export const TLDialog = ({
  iconName,
  title,
  desc,
  titleExtraElement,
  open,
  onClose,
  buttons = [],
  children,
  fullScreen,
  fullWidth,
  maxWidth,
  PaperProps,
  sx = {},
}) => {
  const filteredButtons = filter(Boolean, buttons);
  const renderHeadingWithIcon = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar sx={{ bgcolor: "#F9FAFB", width: 48, height: 48 }}>
          <Avatar sx={{ bgcolor: "#EAECF0", width: 36, height: 36 }}>
            <Icon name={iconName} sx={{ color: "#667085" }} />
          </Avatar>
        </Avatar>
        <H2 id="dialog-title" sx={{ pr: 5 }}>
          {title}
        </H2>
      </Box>
    );
  };
  const renderContent = () => {
    return children;
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-desc"
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={sx}
      PaperProps={PaperProps}
    >
      <DialogTitle
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          // position: "relative",
          // paddingRight: "45px",
        }}
      >
        {iconName ? (
          renderHeadingWithIcon()
        ) : (
          <H2 id="dialog-title" sx={{ pr: 5 }}>
            {title}
          </H2>
        )}
        {desc && <P id="dialog-desc">{desc}</P>}
        {titleExtraElement}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Icon name="Close" />
      </IconButton>
      {renderContent()}
      {!!filteredButtons?.length && (
        <DialogActions>
          {filteredButtons.map(({ Component = Button, ...button }) => (
            <Component key={JSON.stringify(button)} {...button} />
          ))}
        </DialogActions>
      )}
    </StyledDialog>
  );
};
