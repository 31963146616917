import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { descend, prop, sort } from "ramda";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { Header } from "../../components/Header";
import { HistoryRightMenu } from "../../components/HistoryRightMenu";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { Msg, MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { P } from "../../components/Typography";
import { routes } from "../../routes";
import { useMyQuery } from "../Authorization/AuthProvider";
import { I18nContext } from "../I18n/I18nProvider";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { REASON, useMakeSelectable } from "../Values/MyValues";
import { useAreasDict } from "./areas";
import { sessionsMessages } from "./messages";
import { gray50, gray500, primary25, primary500 } from "../../theme";
import { useTheme } from "@emotion/react";
import { useInView } from "react-intersection-observer";
import { useStaticCallback } from "../../hooks/useStaticCallback.hook";

export const SessionCardIconTile = ({
  iconName,
  caption,
  text,
  grayOutEmpty,
  spacious,
  isLoading,
  sx = {},
}) => {
  const { color, bgcolor } =
    // !text && !isLoading && grayOutEmpty
    !text && grayOutEmpty
      ? {
          color: gray500,
          bgcolor: gray50,
        }
      : { color: primary500, bgcolor: primary25 || "#DAD2F1" };

  const { iconSize, outerIconSize, gap, verticalGap } = spacious
    ? { iconSize: 24, outerIconSize: 44, gap: 2, verticalGap: 0.5 }
    : { iconSize: 16, outerIconSize: 32, gap: 1, verticalGap: 0 };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        gap,
        ...sx,
      }}
    >
      <Avatar
        variant="rounded"
        sx={{
          width: outerIconSize,
          height: outerIconSize,
          bgcolor,
          alignSelf: "flex-start",
          // borderRadius: 3,
        }}
      >
        <Icon name={iconName} sx={{ fontSize: iconSize, color }} />
      </Avatar>
      <Box
        sx={{
          // flex: 1, // full width loader
          display: "flex",
          flexDirection: "column",
          gap: verticalGap,
        }}
      >
        <Typography>{caption}</Typography>
        {isLoading ? (
          <Skeleton width={"100%"}>
            <P emphasized>-</P>
          </Skeleton>
        ) : (
          <P emphasized>{text}</P>
        )}
      </Box>
    </Box>
  );
};

export const ActionStepsReadOnly = ({
  steps = [],
  label,
  heading = label && (
    <P emphasized sx={{ mt: 3, mb: 2 }}>
      {label}
    </P>
  ),
  isLoading,
}) => {
  return (
    <Box
      sx={{
        opacity: isLoading ? 0.8 : 1,
        maxWidth: { xs: 200, sm: 250, md: 350, lg: 450, xl: 750 },
      }}
    >
      {heading}
      <ul
        style={{
          paddingLeft: "24px",
          color: "#667085",
          /* gray/500 */
        }}
      >
        {(isLoading ? [{ id: 1 }, { id: 2 }, { id: 3 }] : steps).map(
          ({ id, label, date, checked }, i) => {
            return (
              <li
                key={id}
                style={{
                  paddingTop: "10px",
                  textDecoration: checked ? "line-through" : "none",
                }}
              >
                <P sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {isLoading ? (
                    <Skeleton
                      sx={{
                        minWidth: 100,
                        width: i === 2 ? "70%" : "100%",
                        opacity: 1,
                      }}
                    />
                  ) : (
                    label
                  )}
                </P>
              </li>
            );
          }
        )}
      </ul>
    </Box>
  );
};

const translateSessionType = ({ type, msg }) =>
  msg.maybe(`sessions.card.type.${type}`) || msg(`sessions.card.type.default`);

const SessionCard = ({
  isSelected,
  scrollIntoView,
  onScrollIntoView,
  isLoading,
  session,
  index,
  // session: { timestamp, id = timestamp, date, type, areaOfDevelopment, longTermGoal, motivation, lastReflection, reflection, actionSteps, } = {},
  sx = {},
}) => {
  const { areas } = useAreasDict();
  const { i18n } = useContext(I18nContext);
  const msg = useMsg();
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"));

  const scrollInViewRef = useRef();
  const { ref: isInViewRef } = useInView({
    rootMargin: "-50% 0% -50% 0%",
    delay: 100,
    threshold: 0, // Trigger when (treshold x 100)% of the element is visible
    onChange: (inView, entry) => {
      if (inView && onScrollIntoView) {
        console.log("[SC.rndr] inView", { index, inView, entry });
        onScrollIntoView({ index });
      }
    },
  });

  useEffect(() => {
    if (scrollInViewRef.current && scrollIntoView) {
      console.log("[SC.rndr] scrollIntoView", {
        index,
        scrollIntoView,
        scrollInViewRef,
      });
      setTimeout(() => scrollInViewRef.current.scrollIntoView(), 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollIntoView]);

  // console.log("[SC.rndr]", index, { isSelected, session, scrollInViewRef });

  return (
    <Card
      sx={{ mt: 3, ...sx }}
      // sx={{ mb: 3, boxShadow: "0px 2px 14px 0px #1018280D" }}
      ref={scrollInViewRef}
      elevation={isSelected ? 4 : 1}
    >
      <CardContent
        ref={isInViewRef}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box>
          <P
            sx={{
              visibility: !session?.date || isLoading ? "hidden" : "visible",
            }}
          >
            {i18n.formatLocalMaybe(
              session?.date ? i18n.parseUTCLocal(session.date) : new Date(),
              "P"
            )}
            {upLg && <>&nbsp;-&nbsp;</>}
          </P>
        </Box>
        <Box sx={{ width: "100%" }}>
          <P>
            {session?.type ? (
              translateSessionType({ type: session.type, msg })
            ) : (
              <Skeleton width={100} />
            )}
          </P>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "flex-start",
              gap: 2,
              mt: 3,
            }}
          >
            <SessionCardIconTile
              iconName={"InsertChart"}
              caption={msg("sessions.edit.steps.align.area.caption")}
              text={
                areas[session?.areaOfDevelopment]?.label ||
                session?.areaOfDevelopment
              }
              isLoading={isLoading}
              grayOutEmpty
            />
            <SessionCardIconTile
              iconName={"InsertChart"} // TODO x2
              caption={msg("sessions.edit.steps.align.goal.caption")}
              text={session?.longTermGoal}
              isLoading={isLoading}
              grayOutEmpty
              // text="Giving speeches to audiences regularly" // TODO: width
            />
          </Box>
          <P
            sx={{
              my: 3,
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              lineHeight: 2,
            }}
          >
            {isLoading ? (
              <Skeleton width={"100%"}>
                <P>-</P>
              </Skeleton>
            ) : (
              session?.reflection ||
              session?.lastReflection ||
              session?.motivation
            )}
          </P>
          <ActionStepsReadOnly
            steps={session?.actionSteps}
            label={<Msg id="sessions.card.goals.title" />}
            isLoading={isLoading}
          />
        </Box>
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
};

// const sortAlphaNum = ({ createdAt: a }, { createdAt: b }) => b.localeCompare(a, language, { numeric: true });
export const useSessionsQuery = (rest = {}) => {
  const query = useMyQuery({
    queryKey: ["user-sessions", "history"],
    fetchDef: {
      url: `/api/latest/history/USER_SESSION`,
      to: sort(descend(prop("createdAt"))),
    },
    refetchOnWindowFocus: false,
    gcTime: 0, // otherwise redirected to new-session before cache refresh https://topleader.atlassian.net/browse/TOP-232
    ...rest,
  });
  return query;
};

function Sessions() {
  const msg = useMsg({ dict: sessionsMessages });
  const sessionsQuery = useSessionsQuery();
  const sel = useMakeSelectable({
    entries: sessionsQuery.data ?? [],
    map: (el) => ({
      // Right menu
      status: translateSessionType({ type: el.data.type, msg }),
      id: el.id,
      date: el.createdAt,
      timestamp: new Date(el.createdAt).getTime(),
      // root
      username: el.username,
      type: el.type,
      // data
      areaOfDevelopment: el.data.areaOfDevelopment,
      longTermGoal: el.data.longTermGoal,
      motivation: el.data.motivation,
      lastReflection: el.data.lastReflection,
      reflection: el.data.reflection, // TODO?
      actionSteps: el.data.actionSteps,
    }),
  });
  const navigate = useNavigate();

  console.log("[Sessions.rndr]", {
    sessionsQuery,
    sel,
  });

  if (sessionsQuery.data && !sessionsQuery.data.length)
    return <Navigate to={routes.newSession} replace />;

  return (
    <MsgProvider messages={sessionsMessages}>
      <Layout
        rightMenuContent={
          <HistoryRightMenu
            heading={<Msg id="sessions.aside.title" />}
            history={sel}
            // onRemove={history.remove}
            isLoading={sessionsQuery.isFetching}
            buttonProps={{
              children: <Msg id="sessions.aside.start-button" />,
              onClick: () => navigate(routes.startSession),
            }}
          />
        }
      >
        <Header text={<Msg id="sessions.heading" />} />
        <QueryRenderer
          loaderEl={<Loaders.CircularBlock spaced />}
          // loaderEl={<SessionCard isLoading />}
          query={sessionsQuery}
          success={() =>
            sel.all?.map((session, index) => (
              <SessionCard
                index={index}
                key={session.id}
                session={session}
                isSelected={sel.isSelected(session)}
                scrollIntoView={
                  sel.isSelected(session) &&
                  sel.reason === REASON.MANUAL &&
                  sel.selectionId
                }
                onScrollIntoView={
                  sel.reason === "manual"
                    ? undefined
                    : ({ index }) => {
                        sel.setSelected(session, REASON.SCROLL);
                      }
                }
                // sx={{ flex: "1 1 auto" }}
              />
            ))
          }
        />
      </Layout>
    </MsgProvider>
  );
}

export default Sessions;
