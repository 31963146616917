import { defineMessages } from "react-intl";

export const messages = defineMessages({
  "feedback.list.heading": {
    id: "feedback.list.heading",
    defaultMessage: "Get Feedback",
  },
  "feedback.list.empty.title": {
    id: "feedback.list.empty.title",
    defaultMessage: "There are no feedback forms yet",
  },
  "feedback.list.empty.description": {
    id: "feedback.list.empty.description",
    defaultMessage: "Start by creating a new one",
  },
  "feedback.list.empty.create-btn": {
    id: "feedback.list.empty.create-btn",
    defaultMessage: "Create new form",
  },
  "feedback.list.card.created-at": {
    id: "feedback.list.card.created-at",
    defaultMessage: "Created at {createdAt}",
  },
  "feedback.list.card.responses-count": {
    id: "feedback.list.card.responses-count",
    defaultMessage: "{responsesCount} responses",
  },
  "feedback.list.card.shared-count": {
    id: "feedback.list.card.shared-count",
    defaultMessage: "Shared with {sharedCount} people",
  },
  "feedback.create.heading": {
    id: "feedback.create.heading",
    defaultMessage: "Back to the dashboard",
  },
  "feedback.heading": {
    id: "feedback.heading",
    defaultMessage: "Feedback form",
  },
  "feedback.respondents": {
    id: "feedback.respondents",
    defaultMessage: "Respondents",
  },
  "feedback.submitted": {
    id: "feedback.submitted",
    defaultMessage: "Submitted",
  },
  "feedback.results.shared-with": {
    id: "feedback.results.shared-with",
    defaultMessage: "Shared with",
  },
  "feedback.results.add-email": {
    id: "feedback.results.add-email",
    defaultMessage: "Add email",
  },
  "feedback.results.add": {
    id: "feedback.results.add",
    defaultMessage: "Add",
  },
  "feedback.results.summary": {
    id: "feedback.results.summary",
    defaultMessage: "Summary",
  },
  "feedback.results.summary.strong-areas": {
    id: "feedback.results.summary.strong-areas",
    defaultMessage: "Strong areas",
  },
  "feedback.results.summary.areas-of-improvement": {
    id: "feedback.results.summary.areas-of-improvement",
    defaultMessage: "Areas for improvement",
  },
  "feedback.manual-reminder.form.subject.defaultValue": {
    id: "feedback.manual-reminder.form.subject.defaultValue",
    defaultMessage: "Friendly Reminder: Please Share Your Feedback for {name}",
  },
  "feedback.manual-reminder.form.body.defaultValue": {
    id: "feedback.manual-reminder.form.body.defaultValue",
    defaultMessage: `Hello,

I hope you're doing well. Just a friendly reminder that your feedback on my performance and characteristics on the TopLeader platform is still needed.

Your insights play a key role in my growth, and I would greatly appreciate it if you could take a few minutes to share your thoughts. Your feedback helps me understand areas where I can improve and continue to grow.

Please complete the feedback by {deadlineDate} to ensure it's received on time.

Click here to provide your feedback

By clicking the above link, you acknowledge the Privacy Policy of TopLeader, which can be viewed at Privacy Policy | TopLeader.

Your feedback will remain confidential. Thank you again for your time and valuable input—it really makes a difference.

Best regards,

{name}`,
  },
  "feedback.manual-reminder.title": {
    id: "feedback.manual-reminder.title",
    defaultMessage: "Send Manual Reminder",
  },
  "feedback.manual-reminder.form.subject.label": {
    id: "feedback.manual-reminder.form.subject.label",
    defaultMessage: "Subject",
  },
  "feedback.manual-reminder.form.body.label": {
    id: "feedback.manual-reminder.form.body.label",
    defaultMessage: "Email body",
  },
  "dict.feedback.results.status.initial.text": {
    id: "dict.feedback.results.status.initial.text",
    defaultMessage: "Automatic reminder to be sent on {reminderDate}",
  },
  "dict.feedback.results.status.initial.tooltip": {
    id: "dict.feedback.results.status.initial.tooltip",
    defaultMessage: "Automatic reminder is sent 5 days after the submission",
  },
  "dict.feedback.results.status.reminded.text": {
    id: "dict.feedback.results.status.reminded.text",
    defaultMessage: "Automatic reminder sent on {reminderDate}",
  },
  "dict.feedback.results.status.reminded.tooltip": {
    id: "dict.feedback.results.status.reminded.tooltip",
    defaultMessage: "Manual Reminder Available in {manualInDays} days",
  },
  "dict.feedback.results.status.canRemindManually.text": {
    id: "dict.feedback.results.status.canRemindManually.text",
    defaultMessage: "Automatic reminder sent on {reminderDate}",
  },
  "dict.feedback.results.status.canRemindManually.send": {
    id: "dict.feedback.results.status.canRemindManually.send",
    defaultMessage: "Send Manual Reminder",
  },
  "dict.feedback.results.status.manuallyReminded.text": {
    id: "dict.feedback.results.status.manuallyReminded.text",
    defaultMessage: "Manual reminder sent on {manualDate}",
  },
  "feedback.create.stats.questions": {
    id: "feedback.create.stats.questions",
    defaultMessage: "Questions",
  },
  "feedback.create.stats.required": {
    id: "feedback.create.stats.required",
    defaultMessage: "Required",
  },
  "feedback.create.next-btn": {
    id: "feedback.create.next-btn",
    defaultMessage: "Share form",
  },
  "feedback.create.title.placeholder": {
    id: "feedback.create.title.placeholder",
    defaultMessage: "Title",
  },
  "feedback.create.title.error.exists": {
    id: "feedback.create.title.error.exists",
    defaultMessage: "Title already exists",
  },
  "feedback.create.description.placeholder": {
    id: "feedback.create.description.placeholder",
    defaultMessage: "Description (optional)",
  },
  "feedback.create.add-question": {
    id: "feedback.create.add-question",
    defaultMessage: "Add question",
  },
  "feedback.create.share-modal.title": {
    id: "feedback.create.share-modal.title",
    defaultMessage: "Share feedback form",
  },
  "feedback.create.share-modal.desc": {
    id: "feedback.create.share-modal.desc",
    defaultMessage:
      "Set email addresses and positions of people who you want to share the form with to provide you the feedback.",
  },
  "feedback.create.share-modal.deadline": {
    id: "feedback.create.share-modal.deadline",
    defaultMessage: "Response deadline",
  },
  "feedback.create.share-modal.add-email": {
    id: "feedback.create.share-modal.add-email",
    defaultMessage: "Add email",
  },
  "feedback.create.share-modal.cancel": {
    id: "feedback.create.share-modal.cancel",
    defaultMessage: "Cancel",
  },
  "feedback.create.share-modal.share": {
    id: "feedback.create.share-modal.share",
    defaultMessage: "Share",
  },
  "feedback.create.share-modal.email.placeholder": {
    id: "feedback.create.share-modal.email.placeholder",
    defaultMessage: "Email",
  },
  "feedback.create.share-modal.role.placeholder": {
    id: "feedback.create.share-modal.role.placeholder",
    defaultMessage: "Role",
  },
  "feedback.aside.heading": {
    id: "feedback.aside.heading",
    defaultMessage: "Overview",
  },
  "feedback.aside.text": {
    id: "feedback.aside.text",
    defaultMessage: "Feedback on my leadership skills",
  },
  "feedback.aside.perex": {
    id: "feedback.aside.perex",
    defaultMessage: "Below you can see the overview of your current form.",
  },
  "feedback.aside.collected": {
    id: "feedback.aside.collected",
    defaultMessage: "Responses collected",
  },
  "feedback.external.heading": {
    id: "feedback.external.heading",
    defaultMessage: "Feedback form",
  },
  // "feedback.external.title": {
  //   id: "feedback.external.title",
  //   defaultMessage: "Feedback on {name} performance",
  // },
  "feedback.external.deadline": {
    id: "feedback.external.deadline",
    defaultMessage: "Please answer until {date}",
  },
  "feedback.external.answer-placeholder": {
    id: "feedback.external.answer-placeholder",
    defaultMessage: "Type your answer here",
  },
  "feedback.results.email.placeholder": {
    id: "feedback.results.email.placeholder",
    defaultMessage: "Email",
  },
  "feedback.external.submit": {
    id: "feedback.external.submit",
    defaultMessage: "Submit form",
  },
  "feedback.external.finished-modal.title": {
    id: "feedback.external.finished-modal.title",
    defaultMessage:
      "Thank you for providing your valuable feedback on {user}!!",
  },
  "feedback.external.finished-modal.desc": {
    id: "feedback.external.finished-modal.desc",
    defaultMessage:
      "If you would like to explore more of the TopLeader platform you can request the access",
  },
  "feedback.external.finished-modal.request-access.no": {
    id: "feedback.external.finished-modal.request-access.no",
    defaultMessage: "Done",
  },
  "feedback.external.finished-modal.request-access.yes": {
    id: "feedback.external.finished-modal.request-access.yes",
    defaultMessage: "Request access",
  },

  "feedback.external.request-access-modal.title": {
    id: "feedback.external.request-access-modal.title",
    defaultMessage: "Request access",
  },
  "feedback.external.request-access-modal.desc": {
    id: "feedback.external.request-access-modal.desc",
    defaultMessage: "Please enter your details",
  },
  "feedback.external.request-access-modal.fields.firstName": {
    id: "feedback.external.request-access-modal.fields.firstName",
    defaultMessage: "Name",
  },
  "feedback.external.request-access-modal.fields.lastName": {
    id: "feedback.external.request-access-modal.fields.lastName",
    defaultMessage: "Surname",
  },
  "feedback.external.request-access-modal.fields.email": {
    id: "feedback.external.request-access-modal.fields.email",
    defaultMessage: "Email",
  },
  "feedback.external.request-access-modal.fields.company": {
    id: "feedback.external.request-access-modal.fields.company",
    defaultMessage: "Company (optional)",
  },
  "feedback.external.request-access-modal.fields.hrEmail": {
    id: "feedback.external.request-access-modal.fields.hrEmail",
    defaultMessage: "HR (optional)",
  },
  "feedback.external.request-access-modal.request": {
    id: "feedback.external.request-access-modal.request",
    defaultMessage: "Request access",
  },
  "feedback.unsaved-changes.title": {
    id: "feedback.unsaved-changes.title",
    defaultMessage: "You have unsaved changes",
  },
  "feedback.unsaved-changes.desc": {
    id: "feedback.unsaved-changes.desc",
    defaultMessage: "",
  },
  "feedback.unsaved-changes.close": {
    id: "feedback.unsaved-changes.close",
    defaultMessage: "Return back to form",
  },
  "feedback.unsaved-changes.leave": {
    id: "feedback.unsaved-changes.leave",
    defaultMessage: "Leave anyway",
  },
});
